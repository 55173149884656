import ready from './ready';

let interval = 0;
let timeout = 0;

function tick(element) {
  const count = element.childElementCount;
  const index = Math.floor(Math.random() * count);

  const gridsquare = element.children[index];
  gridsquare.classList.toggle('-effect');
}

function randomise(element) {
  interval = window.setInterval(() => {
    tick(element);
  }, 500);
}

function scrollAway() {
  const landingLayer = document.querySelector('.landing-page');
  landingLayer.classList.add('-scroll-away');
}

function onAction() {
  document.documentElement.classList.add('-revealed');
  scrollAway();
  window.clearTimeout(timeout);
  window.removeEventListener('mousedown', onAction);
  window.removeEventListener('touchstart', onAction);
  window.removeEventListener('wheel', onAction);
}

ready.then(()=>{
  const landing = document.querySelector('.landing__grid');
  if (landing) {
    const griditems = Array.prototype.slice.call(landing.children);
    griditems.forEach(element => {
      tick(landing);
    });
    randomise(landing);
  }

  timeout = window.setTimeout(onAction, 5000);
  window.addEventListener('mousedown', onAction);
  window.addEventListener('touchstart', onAction);
  window.addEventListener('wheel', onAction);
});
