import Swiper from 'swiper/bundle';
import ready from './ready';

var swiper;

function onReadMore(event) {
  // read more button
  if (event.target.matches('.res-slide-info__btn')) {
    if (event.type === 'touchend') {
      event.preventDefault();
    }
    event.target.closest('.swiper-slide').classList.toggle('-show-more');
  }

  // go back button
  if (event.target.matches('.res-slide-info__back')) {
    event.target.closest('.swiper-slide').classList.remove('-show-more');
  }
}

ready.then(() => {
  swiper = new Swiper(".swiper", {
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    lazy: true,
    mousewheel: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });

  swiper.on('slideChange', swiper => {
    for (let i = 0, j = swiper.slides.length; i < j; i++) {
      if (swiper.realIndex === i) {
        continue;
      }
      swiper.slides[i].classList.remove('-show-more');
    }
  });

  window.addEventListener('touchend', onReadMore);
  window.addEventListener('mouseup', onReadMore);
});
