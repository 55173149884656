const checkReady = () => {

  return new Promise(resolve => {
    if (document.readyState.match(/(interactive|complete)/)) {
      resolve();
    } else {
      const onReady = () => {
        resolve();
        document.removeEventListener('DOMContentLoaded', onReady);
        window.removeEventListener('load', onReady);
      };
      document.addEventListener('DOMContentLoaded', onReady);
      window.addEventListener('load', onReady);
    }
  });
};

export default checkReady();
